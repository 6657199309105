






import Vue from 'vue'

export default Vue.extend({
  name: 'SignView',

  components: {
    AccessDeniedSign: () => import('../app/signs/AccessDeniedSign.vue'),
    DontSwitchOnSign: () => import('../app/signs/DontSwitchOnSign.vue'),
  },

  data () {
    return {
    }
  },

  computed: {
    component () {
      return this.$route.query.component
    },
  },
  async mounted () {
  },
  methods: {

  },
})
